import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment/min/moment-with-locales';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import CustomSelect from 'components/CustomSelect';
import { formatNumber, loader, CristalLoader } from 'helpers/generic';
import BusinessChecker from 'components/BusinessChecker';
import {default as TradeCategoriesService} from 'services/TradeCategories';
import TradeCategoryContainer from './TradeCategoryContainer';

const GeneralStyles = styled.section`
	#result {

		#loader {
			width: 40px;
		}
	}
`;

// Set moment locale
moment.locale('es');

export default function BalanceGeneral() {
	const [loading, setLoading] = useState(false);
	let [firstBalancesLoad, setFirstBalancesLoad] = useState(false);
	const [incomeCategories, setIncomeCategories] = useState([]);
	const [incomeBalances, setIncomeBalances] = useState(null);
	const [incomeHasMovements, setIncomeHasMovements] = useState(null);
	const [expenseCategories, setExpenseCategories] = useState([]);
	const [expenseBalances, setExpenseBalances] = useState(null);
	const [expenseHasMovements, setExpenseHasMovements] = useState(null);

	const [financialYears, setFinancialYears] = useState([moment().format('YYYY')]);
	const [financialYear, _setFinancialYear] = useState(moment().format('YYYY'));
	const setFinancialYear = (value) => {
		_setFinancialYear(value);
	}
	const [quarter, _setQuarter] = useState(null);
	const setQuarter = (value) => {
		_setQuarter(value);
		setMonth(null);
	}
	const [month, _setMonth] = useState(""+(moment().month() + 1));
	const setMonth = (value) => {
		_setMonth(value);
	}

	const [incomeSearch, setIncomeSearch] = useState('');
	const [expenseSearch, setExpenseSearch] = useState('');

	const loadCategories = useCallback(async() => {
		setFinancialYears([]);
		setIncomeCategories([]);
		setExpenseCategories([]);

		const [incomesYearsList, expensesYearsList, incomeCategories, expenseCategories] = await Promise.all([
			TradeCategoriesService.financialYearsList('incomes'),
			TradeCategoriesService.financialYearsList('expenses'),
			TradeCategoriesService.listOrdered('incomes', {
				financial_year: financialYear,
				childrens_count: true
			}),
			TradeCategoriesService.listOrdered('expenses', {
				financial_year: financialYear,
				childrens_count: true
			})
		]);

		setFinancialYears([...incomesYearsList, ...expensesYearsList].filter((el, idx, self) => self.indexOf(el) === idx).sort().reverse());
		setIncomeCategories([...incomeCategories]);
		setExpenseCategories([...expenseCategories]);
	}, [financialYear]);

	const loadBalances = useCallback(async () => {
		setIncomeBalances(null);
		setExpenseBalances(null);
		setIncomeHasMovements(null);
		setExpenseHasMovements(null);

		const [incomeBalances, expensesBalances] = await Promise.all([
			TradeCategoriesService.getBalancesBulk('incomes', {
				year: financialYear,
				quarter: quarter,
				month: month
			}),
			TradeCategoriesService.getBalancesBulk('expenses', {
				year: financialYear,
				quarter: quarter,
				month: month
			})
		]);
		setIncomeBalances(incomeBalances);
		setExpenseBalances(expensesBalances);

		const [incomeHasMovements, expensesHasMovements] = await Promise.all([
			TradeCategoriesService.getHasMovementsBulk('incomes', {
				year: financialYear,
				quarter: quarter,
				month: month
			}),
			TradeCategoriesService.getHasMovementsBulk('expenses', {
				year: financialYear,
				quarter: quarter,
				month: month
			})
		]);
		setIncomeHasMovements(incomeHasMovements);
		setExpenseHasMovements(expensesHasMovements);
	}, [financialYear, quarter, month])

	const reloadBalance = async () => {
		setLoading(true);

		TradeCategoriesService.cancel(); // Cancel previous requests

		await Promise.all([
			loadBalances(),
			loadCategories()
		]);

		setLoading(false);
	}

	useEffect(() => {
		if ( firstBalancesLoad ) return;
		firstBalancesLoad = true; // Fix to set the value in the first render and prevent enter again when developing (react strict mode)
		setFirstBalancesLoad(true);

		reloadBalance();
	}, [firstBalancesLoad, reloadBalance]);

	

	let finalResult = null;
	if ( incomeBalances && expenseBalances ) {
		let incomeTotal = 0;
		let expenseTotal = 0;

		for ( let key in incomeBalances ) {
			incomeTotal += incomeBalances[key].own;
		}
		for ( let key in expenseBalances ) {
			expenseTotal += expenseBalances[key].own;
		}
		finalResult = incomeTotal + expenseTotal;
	}

	// Filter incomeCategories
	let incomeCategoriesFilteredIds = [];
	if ( incomeCategories ) {
		incomeCategories.forEach(el => {
			let search = incomeSearch.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			let code = el.code.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			let name = el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			let hasCoincidence = (code + ' ' + name).includes(search);

			if ( hasCoincidence ) {
				incomeCategoriesFilteredIds.push(el.id);

				// Get childs
				incomeCategories.filter(el => el.parent_id === el.id).forEach(el => {
					incomeCategoriesFilteredIds.push(el.id);
				});

				// Get parents recusively
				let parent = el;
				while ( parent.parent_id ) {
					parent = incomeCategories.find(el => el.id === parent.parent_id);
					incomeCategoriesFilteredIds.push(parent.id);
				}
			}
		});
	}
	incomeCategoriesFilteredIds = incomeCategoriesFilteredIds.filter((el, idx, self) => self.indexOf(el) === idx);

	// Filter expenseCategories
	let expenseCategoriesFilteredIds = [];
	if ( expenseCategories ) {
		expenseCategories.forEach(el => {
			let search = expenseSearch.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			let code = el.code.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			let name = el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			let hasCoincidence = (code + ' ' + name).includes(search);

			if ( hasCoincidence ) {
				expenseCategoriesFilteredIds.push(el.id);

				// Get childs
				expenseCategories.filter(el => el.parent_id === el.id).forEach(el => {
					expenseCategoriesFilteredIds.push(el.id);
				});

				// Get parents recusively
				let parent = el;
				while ( parent.parent_id ) {
					parent = expenseCategories.find(el => el.id === parent.parent_id);
					expenseCategoriesFilteredIds.push(parent.id);
				}
			}
		});
	}

	return (
		<LoggedLayout>
			<BusinessChecker />
			{/* {loading && <CristalLoader />} */}
			<GeneralStyles>
				<div className="page-title">
					<h1>Balance general</h1>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
									<CustomSelect label={'Año'} className="form-control form-control-sm" value={financialYear ?? ''} onChange={(e) => setFinancialYear(e.target.value)} disabled={loading}>
										{financialYears.map((el, idx) => {
											return (<option key={idx} value={el}>{el}</option>);
										})}
									</CustomSelect>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<CustomSelect label={'Trimestre'} className="form-control form-control-sm" value={quarter ?? ''} onChange={(e) => setQuarter(e.target.value)} disabled={loading}>
										<option value="">Todos</option>
										<option value="1">1º trimestre</option>
										<option value="2">2º trimestre</option>
										<option value="3">3º trimestre</option>
										<option value="4">4º trimestre</option>
									</CustomSelect>
								</div>
								<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
									<CustomSelect label={'Mes'} className="form-control form-control-sm" value={month ?? ''} onChange={(e) => setMonth(e.target.value)} disabled={loading}>
										<option value="">Todos</option>
										{ [1,2,3,4,5,6,7,8,9,10,11,12].map((el, idx) => {
												if ( !quarter ) return <option key={idx} value={""+el}>{moment().month(el-1).format('MMMM')}</option>;
												if ( quarter === '1' && el <= 3 ) return <option key={idx} value={""+el}>{moment().month(el-1).format('MMMM')}</option>;
												if ( quarter === '2' && el > 3 && el <= 6 ) return <option key={idx} value={""+el}>{moment().month(el-1).format('MMMM')}</option>;
												if ( quarter === '3' && el > 6 && el <= 9 ) return <option key={idx} value={""+el}>{moment().month(el-1).format('MMMM')}</option>;
												if ( quarter === '4' && el > 9 ) return <option key={idx} value={""+el}>{moment().month(el-1).format('MMMM')}</option>;
												return null;
											})
										}
									</CustomSelect>
								</div>
								<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0 d-flex align-items-center">
									<button className="btn btn-primary text-white" onClick={reloadBalance} disabled={loading}>Aplicar</button>
								</div>
							</div>
						</div>
						<div className="card-body">

							<div className="row">
								<div className="col-md-12">
									<h2 id="result" className="py-2">
										Resultado: {(!incomeBalances || !expenseBalances) && loader} {finalResult !== null && formatNumber(finalResult, 2)}
									</h2>
								</div>
								<div className="col-md-6">
									<TradeCategoryContainer
										type={'incomes'}
										title={'Ingresos'}
										categories={incomeCategories}
										balances={incomeBalances}
										search={incomeSearch}
										setSearch={setIncomeSearch}
										categoriesFilteredIds={incomeCategoriesFilteredIds}
										dateFilters={{
											financialYear: financialYear,
											quarter: quarter,
											month: month
										}}
										hasMovements={incomeHasMovements}
										loading={loading}
									/>
								</div>
								<div className="col-md-6">
									<TradeCategoryContainer
										type={'expenses'}
										title={'Gastos'}
										categories={expenseCategories}
										balances={expenseBalances}
										search={expenseSearch}
										setSearch={setExpenseSearch}
										categoriesFilteredIds={expenseCategoriesFilteredIds}
										dateFilters={{
											financialYear: financialYear,
											quarter: quarter,
											month: month
										}}
										hasMovements={expenseHasMovements}
										loading={loading}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</GeneralStyles>
		</LoggedLayout>
	);
}


