import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import randomatic from 'randomatic';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import { CristalLoader } from 'helpers/generic';
import { authUserPermission, getBusiness } from 'helpers/business';
import CustomFieldsPanel from 'components/CustomFieldsPanel';
import Notes from 'components/Notes';
import DocumentsPanel from 'components/DocumentsPanel';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import BusinessChecker from 'components/BusinessChecker';
import Sectors from 'pages/ProvidersForm/Sectors';
import { default as ProvidersService } from 'services/Providers';

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function ProvidersForm() {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		code: randomatic('A0', 5),
		sectors: []
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data, 'provider');
		};
	}, [data]);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				let provider = await ProvidersService.get(params.id);
				if ( provider ) {
					setData({...provider});
				}

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	const deleteProvider = async () => {
		const c = window.confirm('¿Quieres eliminar este proveedor?');
		if ( !c ) return;

		let result = await ProvidersService.delete(data.id);
		if ( result ) {
			toast.info('Proveedor borrado')

			if ( popup ) {
				data = null; // Set data to null to parentWindowProxyCallback
				window.close();
			} else navigate('/providers');
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		data.business_id = getBusiness()?.id;

		let result = null;
		try {
			result = await ProvidersService.save(data);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			setData({...result.provider});
			
			if ( goBack ) {
				if ( popup ) {
					data = result.provider; // Fix to send last data on before unload
					window.close();
				}
				else navigate(-1);
			} else if ( !data.id ) {
		    	navigate('/providers/edit/' + result.provider.id + (popup ? '?popup=true' : ''));
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<BusinessChecker />
			{ loading && <CristalLoader /> }
			<section>
				<div className="page-title">
					<h1>Ficha de proveedor</h1>
					<button onClick={() => popup ? window.close() : navigate('/providers')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-7">

											<LabeledFrame className="mb-3" label="Datos básicos">
												<div className="row">
													<div className="col col-static-100">
														<div className="mb-2">
															<CustomInput label="Código" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} readOnly={!canEdit} />
															{ errors.code &&
																<div className="invalid-feedback d-block">{ errors.code[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} readOnly={!canEdit} />
															{ errors.name &&
																<div className="invalid-feedback d-block">{ errors.name[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput label="Nombre comercial" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('brand_name', e.target.value)} value={data.brand_name ?? ''} readOnly={!canEdit} />
															{ errors.brand_name &&
																<div className="invalid-feedback d-block">{ errors.brand_name[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput label="CIF" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('vatnumber', e.target.value)} value={data.vatnumber ?? ''} readOnly={!canEdit} />
															{ errors.vatnumber &&
																<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput label="Teléfono" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('phone', e.target.value)} value={data.phone ?? ''} readOnly={!canEdit} />
															{ errors.phone &&
																<div className="invalid-feedback d-block">{ errors.phone[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-250">
														<div className="mb-2">
															<CustomInput label="Página web" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('website', e.target.value)} value={data.website ?? ''} readOnly={!canEdit} />
															{ errors.website &&
																<div className="invalid-feedback d-block">{ errors.website[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput label="Email" type="email" className="form-control form-control-sm" onChange={(e) => setDataField('email', e.target.value)} value={data.email ?? ''} readOnly={!canEdit} />
															{ errors.email &&
																<div className="invalid-feedback d-block">{ errors.email[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-80">
														<div className="mb-2">
															<CustomInput label="Descuento" type="number" step="0.01" className="form-control form-control-sm" onChange={(e) => setDataField('discount', e.target.value)} value={data.discount ?? ''} readOnly={!canEdit} />
															{ errors.discount &&
																<div className="invalid-feedback d-block">{ errors.discount[0] }</div>
															}
														</div>
													</div>
												</div>											
											</LabeledFrame>

											<LabeledFrame className="mb-3" label="Dirección">
												<div className="row">
													<div className="col col-static-250">
														<div className="mb-2">
															<CustomInput label="Dirección" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('address', e.target.value)} value={data.address ?? ''} readOnly={!canEdit} />
															{ errors.address &&
																<div className="invalid-feedback d-block">{ errors.address[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-80">
														<div className="mb-2">
															<CustomInput label="C.P." type="text" className="form-control form-control-sm" onChange={(e) => setDataField('postalcode', e.target.value)} value={data.postalcode ?? ''} readOnly={!canEdit} />
															{ errors.postalcode &&
																<div className="invalid-feedback d-block">{ errors.postalcode[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput label="Ciudad" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('city', e.target.value)} value={data.city ?? ''} readOnly={!canEdit} />
															{ errors.city &&
																<div className="invalid-feedback d-block">{ errors.city[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-160">
														<div className="mb-2">
															<CustomInput label="Provincia" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('province', e.target.value)} value={data.province ?? ''} readOnly={!canEdit} />
															{ errors.province &&
																<div className="invalid-feedback d-block">{ errors.province[0] }</div>
															}
														</div>
													</div>
													<div className="col-lg-2">
														<div className="mb-2">
															<CustomInput label="País" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('country', e.target.value)} value={data.country ?? ''} readOnly={!canEdit} />
															{ errors.country &&
																<div className="invalid-feedback d-block">{ errors.country[0] }</div>
															}
														</div>
													</div>
												</div>				
											</LabeledFrame>
										</div>
										<div className="col-lg-5">
											<Sectors
												sectors={data.sectors}
												setSectors={(newSectors) => setData({...data, sectors: newSectors})}
												errors={errors}
												readOnly={!canEdit}
											/>

											<CustomFieldsPanel 
												className="mb-3"
												type="providers"
												fields={data.customfields_array}
												setFields={(fields) => setData((data) => ({...data, customfields_array: fields}))}
												readOnly={!canEdit}
											/>

											<LabeledFrame className="mb-3" label="Instrucciones">
												<textarea className="form-control form-control-sm" onChange={(e) => setDataField('observations', e.target.value)} value={data.observations ?? ''} readOnly={!canEdit}></textarea>
											</LabeledFrame>
											
											<Notes
												className="mb-3"
												notes={data.notes ?? []}
												updateParentCallback={(notes) => setData((data) => ({...data, notes: notes}))}
												readOnly={!canEdit}
											/>

											{ data.id && 
												<DocumentsPanel
													className="mb-3"
													folderId={data.folder_id ?? null}
													business={getBusiness()}
												/>
											}
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && !data.is_used && authUserPermission('delete') ) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteProvider()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ ((data.id && authUserPermission('edit')) || authUserPermission('add') ) &&
												<div className="dropdown d-inline">
													<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
														Acciones
													</button>
													<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
														<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
														<li><hr className="dropdown-divider" /></li>

														<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
														<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
													</ActionsContextMenu>
												</div>
											}
											
											{ ((data.id && authUserPermission('edit')) || authUserPermission('add') ) &&
												<>
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>
												</>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}