import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { openPopupWindow } from 'helpers/generic';
import { getRouterBasename } from 'helpers/config';
import { default as SectorsService } from 'services/Sectors';

const SectorsWrapper = styled.div`
	.empowered-selector {

		.empowered-selector_label {
			background: none;
			border: none;
			font-size: 11px;
			padding: 1px !important;
			min-height: auto !important;
			margin: 0;
			color: var(--bs-primary);

			.dropdown-toggle-icon {
				display: none;
			}
		}

		.dropdown-menu {
			right: -11px;
			border-top: 1px solid var(--bs-gray-300);
		}
	}
`;

const SectorsContainer = styled.div`

`;

const Sector = styled.div`
	position: relative;
	display: inline-block;
	margin: 2px 5px;
	padding: 1px 10px;
	background: var(--bs-light);
	font-size: 12px;
	border-radius: 3px;

	&:hover {
		background: var(--bs-gray-400);
	}

	button {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: -5px;
		right: -5px;
		background: var(--bs-red);
		color: white;
		border-radius: 50%;
		width: 10px;
		height: 12px;
		font-size: 10px;
		border: 0;

		&:hover {
			transform: scale(1.2);
		}
	}

	&.has-errors {
		background: rgba(var(--bs-danger-rgb), 0.5);
	}
`;

export default function Sectors(props) {
	let sectors = props.sectors ?? [];
	let setSectors = props.setSectors;
	let errors = props.errors ?? [];
	let readOnly = props.readOnly;

	useEffect(() => {
		window.PopupProxyCallbackSector = (newSector) => {
            if ( newSector?.id ) {
				addSector(newSector, sectors);
			}
		}

		return function cleanup() {
			window.PopupProxyCallbackSector = null;
		}
	}, [sectors]);

	const openNewSectorPopupWindow = () => {
		openPopupWindow(getRouterBasename() + '/sectors/add?popup=true');
	}
 
	const addSector = (data, sectors) => {
		if ( data === -99 ) {
			openNewSectorPopupWindow()
			return;
		}

		let newSectors = [...sectors];
		newSectors.push({
			id: data.id,
			name: data.name,
			order: data.order
		});
		setSectors(newSectors);
	}

	const removeSector = (idx) => {
		// let c = window.confirm('¿Seguro que quieres eliminar este sector?');
		// if ( !c ) return false;

		let s = [...sectors];
		s.splice(idx, 1);
		setSectors(s);
	}

	const loadSectors = async (input, callback, args) => {
		let result = await SectorsService.list({
			search: input,
			no_paginate: true,
			except_ids: args.except_ids
		});

		let formatted = result.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});
		formatted.unshift({
			value: -99,
			label: <div className="text-center">
						<button className="btn btn-primary btn-sm text-white p-0 px-2">Crear sector</button>
					</div>
		})
		callback(formatted);
	}

	let rows = [];
	sectors
		.sort((a,b) => { 
			if ( a.order < b.order ) return -1;
			if ( a.order > b.order ) return 1;
			return 0;
		})
		.forEach((el, idx) => {
			rows.push(
				<Sector key={idx} className={errors['sectors.' + idx + '.id'] ? 'has-errors' : ''}>
					<button disabled={readOnly} className="btn btn-sm" onClick={() => removeSector(idx)}><i className="bi bi-x"></i></button>
					{el.name}
				</Sector>
			);
		});

	return (
		<SectorsWrapper>
			<LabeledFrame 
				className="mb-3" 
				label="Sectores"
				buttons={
					!readOnly ?
						<EmpoweredSelector
							load={loadSectors}
							onChange={(value) => addSector(value, sectors)}
							args={{except_ids: sectors.map((el) => el.id)}}
							timeout={250}
							label={<span><i className="bi bi-plus-circle-fill"></i> Asociar</span>}
							value={-1}
							noRemovable={true}
						/>
						:
						null
				}
			>
				{ rows.length > 0 &&
					<SectorsContainer>
						{rows}
					</SectorsContainer>
				}
				{ errors && errors['sectors'] &&
					<div className="invalid-feedback d-block">
						{errors['sectors'][0]}
					</div>
				}
			</LabeledFrame>
		</SectorsWrapper>
	);
}