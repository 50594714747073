import React, { useRef, useEffect, useState } from "react";
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { CristalLoader } from "helpers/generic";
import { default as ProvidersService } from 'services/Providers';

const ModalStyled = styled.div`
    .modal {
	    background: rgba(0, 0, 0, 0.4);

        .modal-body {
            
        }
    }
`;

export default function CopyProviderModal(props) {
	const modalRef = useRef(null);

    const provider = props.provider;
    const newBusinessId = props.newBusinessId;
    const closeCallback = props.closeCallback;

    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const modal = new Modal(modalRef.current, {
			backdrop: false,
            keyboard: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

        modal.show();
    }, [closeCallback]);

    const closeModal = (newProviderData = null) => {
        const modal = Modal.getInstance(modalRef.current);
		modal.hide();

        closeCallback(newProviderData);
    }

    const copy = async () => {
        setLoading(true);

        // Prepare data
        let data = {
            code: provider.code,
            name: provider.name,
            vatnumber: provider.vatnumber,
            address: provider.address,
            city: provider.city,
            postalcode: provider.postalcode,
            province: provider.province,
            country: provider.country,
            email: provider.email,
            phone: provider.phone,
        }

        // Save
        let result = await ProvidersService.save(data, newBusinessId);
        if ( result.status ) {
            closeModal(result.provider);
        }

        setLoading(false);
    }

	return (
        <ModalStyled>
            {loading && <CristalLoader />}
            <div className="modal" tabIndex="-1" ref={modalRef}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">No existe el proveedor</h5>
                        </div>
                        <div className="modal-body">
                            El proveedor que estaba seleccionado no existe en la nueva empresa que has seleccionado. ¿Qué quieres hacer?
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-sm btn-primary text-white me-2" onClick={() => copy()}>Copiar proveedor a la nueva empresa</button>
                            <button className="btn btn-sm btn-light" onClick={() => closeModal(null)}>No hacer nada</button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyled>
	);
}