import React, { useEffect, useState, useRef } from "react";
import styled from 'styled-components';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { openPopupWindow, formatNumber, loader } from 'helpers/generic';
import { getRouterBasename } from "helpers/config";
import { default as BankReconciliationService } from 'services/BankReconciliation';
import { default as InvoicesService } from 'services/Invoices';
import { default as ProviderInvoicesService } from 'services/ProviderInvoices';
import EmpoweredSelector from "components/EmpoweredSelector";

const ModalStyled = styled.div`
	background: rgba(0,0,0, 0.7);
`;

const Loader = styled.div`
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
`;

let newInvoiceWindow = null;

export default function TradeCategoryModal(props) {
	const modalRef = useRef(null);
	const callbackDataRef = useRef(null);

	const data = props.data;
	const closeCallback = props.closeCallback;

	const [modalSize, setModalSize] = useState('lg');
	const [creatingInvoice, setCreatingInvoice] = useState(false);
	const [invoice, setInvoice] = useState(null);
	const [selectExistingInvoice, setSelectExistingInvoice] = useState(false);
	const [loading, setLoading] = useState(false);

	let entity = data.reconciliation[0].entity;

	const hideModal = () => {
		const modal = Modal.getInstance(modalRef.current);
		if ( modal ) modal.hide();
	}

	useEffect(() => {

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
			if ( e.target !== modal._element ) return; // Fix to avoid close when a child modal is closed

           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			closeCallback(callbackDataRef.current);

			// Close new invoice window if modal is closed and new window is opened
			if ( newInvoiceWindow && !newInvoiceWindow.closed ) newInvoiceWindow.close();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);
		modal.show();
	}, [closeCallback]);

	useEffect(() => {
		window.PopupProxyCallback = (newInvoice) => {
            if ( newInvoice?.id ) {
				updateBankReconciliation(newInvoice);
			}
			setCreatingInvoice(false);
		}

		return function cleanup() {
			window.PopupProxyCallback = null;
		}
	}, []);

	useEffect(() => {
		setModalSize(selectExistingInvoice ? 'md' : 'lg');
	}, [selectExistingInvoice]);

	const updateBankReconciliation = async (invoice) => {
		setLoading(true);

		// Delete previous
		let result = await BankReconciliationService.remove(data.reconciliation[0].bank_reconciliation_id);
		if ( !result ) {
			toast.error('Ha ocurrido un error al guardar');
			return;
		}

		// Create new reconciliation
		let movements = []
		movements.push({
			class: (() => {
				let entityClass = '';
				if ( entity.type === 'incomes' ) entityClass = 'Invoice';
				if ( entity.type === 'expenses' ) entityClass = 'ProviderInvoice';
				return 'App\\Models\\' + entityClass;
			})(),
			concept: invoice.number,
			id: invoice.id,
			amount: invoice.total
		});
		movements.push({
			class: 'App\\Models\\BankMovement',
			concept: data.concept,
			id: data.id,
			amount: data.amount,
			date: data.date,
		});

		let reconciliationType = '';
		if ( entity.type === 'incomes' ) reconciliationType = 'invoice';
		if ( entity.type === 'expenses' ) reconciliationType = 'provider-invoice';

		let saveBankReconciliationResult = await BankReconciliationService.save(reconciliationType, movements, {category_id: entity.id});
		if ( saveBankReconciliationResult ) {
			toast.success('Movimientos conciliados');
		} else {
			toast.error('Ha ocurrido un error al guardar');
		}

		callbackDataRef.current = invoice.date;

		hideModal();
	}

	const createNewInvoice = () => {	
		if ( entity.type === 'incomes' ) {
			newClientInvoice();
		}

		if ( entity.type === 'expenses' ) {
			newProviderInvoice();
		}

		setCreatingInvoice(true);
	}

	const newClientInvoice = () => {
		newInvoiceWindow = openPopupWindow(
			getRouterBasename() + '/invoices/add?popup=true' + 
			'&page_title=Convertir categoría a factura' +
			'&date_reception=' + moment(data.date).format('YYYY-MM-DD') +
			'&amount=' + (data.amount * -1) +
			'&concept=' + data.concept +
			'&hidden_sections=payments,save_button' +
			'&fixed_total=' + (data.amount * -1) + 
			'&category_id=' + entity.id
		);
	}

	const newProviderInvoice = () => {
		newInvoiceWindow = openPopupWindow(
			getRouterBasename() + '/providers-invoices/add?popup=true' + 
			'&page_title=Convertir categoría a factura' +
			'&date_reception=' + moment(data.date).format('YYYY-MM-DD') +
			'&amount=' + (data.amount * -1) +
			'&concept=' + data.concept +
			'&hidden_sections=payments,save_button' +
			'&fixed_total=' + (data.amount * -1) + 
			'&category_id=' + entity.id
		);
	}

	const loadInvoices = async (type, input, callback) => {
		let paid_statuses = ['unpaid', 'partial', 'manual'];

		let invoices = [];

		for(let i = 0; i < paid_statuses.length; i++) {
			let paid_status = paid_statuses[i];
			let result = [];

			if ( type === 'client' )  {
				result = await InvoicesService.list({
					search: input,
					no_paginate: true,
					paid_status: paid_status
				});
			}
	
			if ( type === 'provider' )  {
				result = await ProviderInvoicesService.list({
					search: input,
					no_paginate: true,
					paid_status: paid_status
				});
			}

			invoices.push(...result);
		}
		
		let formatted = invoices?.map((el) => {
			let iType = el.hasOwnProperty('client_id') ? 'client' : 'provider';

			if ( iType === 'client' ) {
				return {
					value: {...el, labelFix: el.number_full + ' / ' + moment(el.date).format('DD-MM-YYYY') + ' / ' + el.client_data?.name}, 
					label: (
						<div>
							{el.number_full} / {moment(el.date).format('DD-MM-YYYY')} / {el.client_data?.name}
							<div><small>{el.total}€</small></div>
						</div>
					)
				}
			}

			if ( iType === 'provider') {
				return {
					value: {...el, labelFix: el.number + ' / ' + moment(el.date).format('DD-MM-YYYY') + ' / ' + el.provider?.name},
					label: (
						<div>
							{el.number} / {moment(el.date).format('DD-MM-YYYY')} / {el.provider?.name}
							<div><small>{el.total}€</small></div>
						</div>
					)
				}
			}
		});
		callback(formatted);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className={"modal-dialog modal-" + modalSize}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Detalles de la categoría</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						{ creatingInvoice &&
							<div className="col-md-12 mt-3 text-center">
								Esperando la creación de la factura en la ventana abierta... 
								<Loader>{loader}</Loader>
							</div>
						}

						{ selectExistingInvoice &&
							<>
								<div className="col-md-12 my-4">
									<div className="row justify-content-center align-items-center">
										<div className="col-md-12 text-center mb-4">
											<h5>Seleccionar factura existente</h5>
										</div>
										<div className="col-md-10">
											<EmpoweredSelector
												load={(input, callback) => loadInvoices(entity.type === 'incomes' ? 'client' : 'provider', input, callback)}
												onChange={(value) => setInvoice(value)}
												timeout={250}
												label={invoice ? invoice.labelFix : ''}
												placeholder="Seleccionar factura"
												value={invoice?.id}
											/>
										</div>
										<div className="col-md-2">
											<button className="btn btn-sm btn-primary text-white w-100" onClick={() => updateBankReconciliation(invoice)} disabled={!invoice}>Aceptar</button>
										</div>
									</div>
								</div>
							</>
						}

						{ (!creatingInvoice && !selectExistingInvoice) &&
							<table className="table table-sm table-bordered">
								<thead>
									<tr>
										<td colSpan="100%">
											Categorías
										</td>
									</tr>
								</thead>
								<tbody>
									{data.reconciliation.map((entity, index) => (
										<tr key={index}>
											<td>
												<div className="d-flex justify-content-between">
													<span>{entity.entity.concept}</span>
													{/* <button className="btn btn-sm btn-link p-0" onClick={() => openEstimateWindow(entity.entity.id)}> <i className="bi bi-eye"></i> </button> */}
												</div>
											</td>
											<td className="text-end">{formatNumber(entity.entity.amount, 2)}</td>
										</tr>
									))}
								</tbody>
							</table>
						}
					</div>
					{ (!creatingInvoice && !selectExistingInvoice) && 
						<div className="modal-footer">
							<button className="btn btn-primary text-white" onClick={() => setSelectExistingInvoice(true)} disabled={loading}>Seleccionar factura existente</button>
							<button className="btn btn-primary text-white" onClick={() => createNewInvoice()} disabled={loading}>Crear factura</button>
						</div>
					}
				</div>
			</div>
		</ModalStyled>
	);
}


