import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/min/moment-with-locales';
import styled from 'styled-components';
import Category from './Category';
import { default as PrevisionsService } from 'services/Previsions';

const PanelStyled = styled.div`
    margin-bottom: 10px;

    .thead-sticky {
        position: sticky;
        top: ${props => props.stickyTop}px;
        background: white;
        z-index: 2;
    }

    .thead-hidden {
        
        thead {
            visibility: hidden;
            height: 0;
            
            tr {
                th {
                    padding: 0 !important;
                    height: 0 !important;
                    line-height: 0 !important;
                    border: none !important;
                }
            }
        }
    }
    
    .table-wrapper {
        overflow-x: hidden;
        overflow-y: auto;
    }

    table {
        margin-bottom: 0;

        thead, tfoot {

            tr {
                &:first-child > th:first-child {
                    position: sticky;
                    left: 0;
                    background: var(--bs-gray-100);

                    button {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 0;
                        background: transparent;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            transform: scale(1.03);
                        }
                    }
                }

                th {
                    text-transform: capitalize;
                    text-align: center;
                    vertical-align: middle;

                    &.row-name {
                        --width: 250px;
                        min-width: var(--width);
                        max-width: var(--width);
                        font-size: 20px;
                        text-transform: uppercase;
                        font-weight: 600;
                    }

                    &.day {
                        --width: 50px;
                        min-width: var(--width);
                        max-width: var(--width);
                    }

                    &.month {
                        --width: 300px;
                        min-width: var(--width);
                        max-width: var(--width);
                    }

                    &.month-sub {
                        width: 33%;
                        font-weight: 500;
                    }
                }
            }
        }

        tfoot {

            tr {

                th {
                    z-index: 2;
                    font-weight: 600;
                }

                td {
                    position: relative;

                    input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        background: transparent !important;
                        border: 0;
                        padding: 0;
                        outline: none;
                        font-weight: 600;
                    }
                }
            }
        }

        thead, tbody, tfoot {

            tr {

                th, td {

                    &.accumulated {
                        background: var(--bs-gray-100);
                    }
                }
            }
        }
    }	
`;

const CategoriesPanel = (props) => {
    const { categories, type, data, setCategoryData, canEdit, months, previsionId, errors, forceReload } = props;

    const [realAmounts, setRealAmounts] = useState([]);
    const [stickyTop, setStickyTop] = useState(0);

    const realAmountsSwitchFixRef = useRef(false);

    useEffect(() => {
        let getRealAmounts = async () => {
            let categoriesIds = categories?.map(row => row.id);
            if ( !categoriesIds?.length || !previsionId ) return;

            if ( realAmountsSwitchFixRef.current === JSON.stringify(categoriesIds) ) return; // Prevent reload when changing prevision on tables
            realAmountsSwitchFixRef.current = JSON.stringify(categoriesIds);
        
            let realAmounts = await PrevisionsService.getCategoriesRealAmount(previsionId, categoriesIds);
            setRealAmounts(realAmounts);
        }
        getRealAmounts();
    }, [type, categories, previsionId, forceReload]);

    useEffect(() => {
        let stickyTop = document.querySelector('.page-title').getBoundingClientRect().bottom;
        setStickyTop(stickyTop);
    }, []);

    let monthsData = months.map((month) => {
        return {
            numeric: month,
            name: moment('2024-' + ((month < 10 ? '0' : '') + month) + '-01').locale('es').format('MMMM'),
        };
    });

    let accumulated = {
        totals: {
            prevision: 0,
            real: 0,
            diff: 0
        }
    };
    categories?.forEach(category => {
        let catKey = category.id;
        if ( category.type === 'incomes' && type === 'balance' ) catKey = -1;
        if ( category.type === 'expenses' && type === 'balance' ) catKey = -2;

        // Prevision
        let catData = data
                        ?.filter(el => {
                            // If balance
                            if (catKey === -1) return el.type === 'incomes';
                            if (catKey === -2) return el.type === 'expenses';

                            // Default
                            return true;
                        })
                        ?.filter(el => el.category_id === category.id)[0] ?? null;

        let prevision = 0;
        if ( catData && catData.monthsAmount ) {
            Object.keys(catData.monthsAmount).forEach(key => {
                let amount = parseFloat(catData.monthsAmount[key]?.amount ?? 0);
                if ( isNaN(amount) ) amount = 0;
                if ( category.type === 'expenses' && type === 'balance' ) amount *= -1;
                prevision += amount;
            });
        }

        // Real
        let real = 0;
        if ( realAmounts && realAmounts[category.id] ) {
            Object.keys(realAmounts[category.id]).forEach(key => {
                let amount = parseFloat(realAmounts[category.id][key] ?? 0);
                if ( isNaN(amount) ) amount = 0;
                if ( category.type === 'expenses' && type === 'balance' ) amount *= -1;
                real += amount;
            });
        }
        
        // Diff
        let diff = real - prevision;

        // Set
        if ( !accumulated[catKey] ) {
            accumulated[catKey] = {
                prevision: 0,
                real: 0,
                diff: 0
            };
        }
        accumulated[catKey]['prevision'] += prevision;
        accumulated[catKey]['real'] += real;
        accumulated[catKey]['diff'] += diff;

        // Set totals
        accumulated.totals.prevision += prevision;
        accumulated.totals.real += real;
        accumulated.totals.diff += diff
    });

    // console.log(accumulated);

    return (
        <PanelStyled stickyTop={stickyTop}>
            <div className="table-wrapper thead-sticky" id={'ftw-' + uuidv4()} data-synced-scroll="true">
                <table className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th rowSpan="2" className="row-name">
                                {type === 'incomes' && <>Ingresos</>}
                                {type === 'expenses' && <>Gastos</>}
                                {type === 'balance' && <>Resultado</>}
                            </th>
                            <th rowSpan="2" className="day">Día</th>
                            { [...monthsData].map((month) => {
                                return (
                                    <th key={'month-' + month.numeric} className={'month ' + (month.numeric === 13 ? 'accumulated' : '')} colSpan="3">{month.name}</th>
                                );
                            })}
                            <th colSpan="3" className="month accumulated">Acumulado</th>
                        </tr>
                        <tr>
                            { [...monthsData].map((month) => {
                                return (
                                    <React.Fragment key={'month-' + month.numeric + '-sub'}>
                                        <th className={'month-sub'}>Previsto</th>
                                        <th className={'month-sub'}>Real</th>
                                        <th className={'month-sub'}>Diferencia</th>
                                    </React.Fragment>
                                );
                            })}

                            <th className={'month-sub accumulated'}>Previsto</th>
                            <th className={'month-sub accumulated'}>Real</th>
                            <th className={'month-sub accumulated'}>Diferencia</th>
                        </tr>
                    </thead>
                </table>
            </div>

            <div className="table-wrapper thead-hidden" id={'ftw-' + uuidv4()} data-synced-scroll="true">
                <table className="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th rowSpan="2" className="row-name">
                                {type === 'incomes' && <>Ingresos</>}
                                {type === 'expenses' && <>Gastos</>}
                            </th>
                            <th rowSpan="2" className="day">Día</th>
                            { [...monthsData].map((month) => {
                                return (
                                    <th key={'month-' + month.numeric} className={'month ' + (month.numeric === 13 ? 'accumulated' : '')} colSpan="3">{month.name}</th>
                                );
                            })}
                            <th colSpan="3" className="month accumulated">Acumulado</th>
                        </tr>
                        <tr>
                            { [...monthsData].map((month) => {
                                return (
                                    <React.Fragment key={'month-' + month.numeric + '-sub'}>
                                        <th className={'month-sub'}>Previsto</th>
                                        <th className={'month-sub'}>Real</th>
                                        <th className={'month-sub'}>Diferencia</th>
                                    </React.Fragment>
                                );
                            })}

                            <th className={'month-sub accumulated'}>Previsto</th>
                            <th className={'month-sub accumulated'}>Real</th>
                            <th className={'month-sub accumulated'}>Diferencia</th>
                        </tr>
                    </thead>
                    <tbody>
                        { type !== 'balance' &&
                            <>
                                {categories?.map((category, idx) => {
                                    let catData = data?.filter(el => el.category_id === category.id)[0] ?? {};

                                    // Fix
                                    catData = JSON.parse(JSON.stringify(catData));

                                    const realAmountsSingleRow = realAmounts[category.id] ?? null;

                                    return (
                                        <Category 
                                            key={'row' + category.id}
                                            category={category}
                                            data={catData}
                                            setData={setCategoryData}
                                            realAmounts={realAmountsSingleRow}
                                            canEdit={canEdit}
                                            months={months}
                                            accumulated={accumulated[category.id] ?? null}
                                            errors={errors && errors[category.id] ? errors[category.id] : null}
                                        />
                                    );
                                })}
                            </>
                        }

                        { type === 'balance' &&
                            <>
                                <Category 
                                    key={'row-incomes'}
                                    category={{
                                        id: -1,
                                        name: 'Ingresos totales',
                                        is_used: true
                                    }}
                                    data={{
                                        monthsAmount: (() => {
                                            let monthsAmount = {1: {amount: 0}, 2: {amount: 0}, 3: {amount: 0}, 4: {amount: 0}, 5: {amount: 0}, 6: {amount: 0}, 7: {amount: 0}, 8: {amount: 0}, 9: {amount: 0}, 10: {amount: 0}, 11: {amount: 0}, 12: {amount: 0}};
                                            data?.filter(el => el.type === 'incomes')?.forEach(el => {
                                                for(let i=1; i<=12; i++) {
                                                    monthsAmount[i].amount += el.monthsAmount[i]?.amount ? parseFloat(el.monthsAmount[i]?.amount) : 0;
                                                }
                                            });
                                            return monthsAmount;
                                        })()
                                    }}
                                    setData={setCategoryData}
                                    realAmounts={(() => {
                                        let monthsAmount = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0};
                                        for(let catData of categories.filter(el => el.type === 'incomes')) {
                                            for(let i=1; i<=12; i++) {
                                                monthsAmount[i] += realAmounts && realAmounts[catData.id] && realAmounts[catData.id][i] ? parseFloat(realAmounts[catData.id][i]) : 0;
                                            }
                                        }
                                        return monthsAmount;
                                    })()}
                                    canEdit={canEdit}
                                    months={months}
                                    accumulated={accumulated[-1]}
                                    errors={null}
                                />

                                <Category 
                                    key={'row-expenses'}
                                    category={{
                                        id: -2,
                                        name: 'Gastos totales',
                                        level: 2,
                                        is_used: true
                                    }}
                                    data={{
                                        monthsAmount: (() => {
                                            let monthsAmount = {1: {amount: 0}, 2: {amount: 0}, 3: {amount: 0}, 4: {amount: 0}, 5: {amount: 0}, 6: {amount: 0}, 7: {amount: 0}, 8: {amount: 0}, 9: {amount: 0}, 10: {amount: 0}, 11: {amount: 0}, 12: {amount: 0}};
                                            data?.filter(el => el.type === 'expenses')?.forEach(el => {
                                                for(let i=1; i<=12; i++) {
                                                    monthsAmount[i].amount += el.monthsAmount[i]?.amount ? parseFloat(el.monthsAmount[i]?.amount) : 0;
                                                }
                                            });
                                            return monthsAmount;
                                        })()
                                    }}
                                    setData={setCategoryData}
                                    realAmounts={(() => {
                                        let monthsAmount = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0};
                                        for(let catData of categories.filter(el => el.type === 'expenses')) {
                                            for(let i=1; i<=12; i++) {
                                                monthsAmount[i] += realAmounts && realAmounts[catData.id] && realAmounts[catData.id][i] ? parseFloat(realAmounts[catData.id][i]) : 0;
                                            }
                                        }
                                        return monthsAmount;
                                    })()}
                                    canEdit={canEdit}
                                    months={months}
                                    accumulated={accumulated[-2]}
                                    errors={null}
                                />
                            </>
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Total</th>
                            <th></th>
                            { [...monthsData].map(month => {
                                let prevision = 0;
                                categories?.forEach(category => {
                                    let catData = data?.filter(el => el.category_id === category.id)[0] ?? null;
                                    if ( catData ) {
                                        let amount = catData.monthsAmount[month.numeric] ? parseFloat(catData.monthsAmount[month.numeric]?.amount) : 0;
                                        if ( isNaN(amount) ) amount = 0;
                                        if ( category.type === 'expenses' && type === 'balance' ) amount *= -1;
                                        prevision += amount;
                                    }
                                });

                                let real = 0;
                                categories?.forEach(category => {
                                    let amount = realAmounts && realAmounts[category.id] && realAmounts[category.id][month.numeric] ? parseFloat(realAmounts[category.id][month.numeric]) : 0;
                                    if ( isNaN(amount) ) amount = 0;
                                    if ( category.type === 'expenses' && type === 'balance' ) amount *= -1;
                                    real += amount;
                                });

                                let diff = real ? real - prevision : 0;

                                return (
                                    <React.Fragment key={'month-' + month.numeric + '-total'}>
                                        <td><input type="number" className="no-arrows" step="0.01" value={prevision ? prevision.toFixed(2) : ''} readOnly /></td>
                                        <td><input type="number" className="no-arrows" step="0.01" value={real ? real.toFixed(2) : ''} readOnly /></td>
                                        <td><input type="number" className="no-arrows" step="0.01" value={diff ? diff.toFixed(2) : ''} readOnly /></td>
                                    </React.Fragment>
                                );
                            })}
                            <td className="accumulated">
                                <input type="number" className="no-arrows" value={accumulated.totals.prevision.toFixed(2)} readOnly />
                            </td>
                            <td className="accumulated">
                                <input type="number" className="no-arrows" value={accumulated.totals.real.toFixed(2)} readOnly />
                            </td>
                            <td className="accumulated">
                                <input type="number" className="no-arrows" value={accumulated.totals.diff.toFixed(2)} readOnly />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </PanelStyled>
    );
}

export default React.memo(CategoriesPanel);